import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiji-groups-card',
    templateUrl: './groups-card.component.html',
    styleUrls: ['./groups-card.component.scss'],
    standalone: false
})
export class GroupsCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

<div class="row">
  <div class="col-12 mt-3 animate__animated animate__bounceInUp">
    <div class="bg-white border rounded-3 d-flex flex-column h-100 p-3">
      <div class="row">
        <div class="col text-center fw-bold">
          {{ 'mif.coach.user-list.Menu coach' | translate }}
        </div>
      </div>
      @if (loading) {
        <div class="row">
          <div class="col">
            <i class="fa-light fa-spinner spinning"></i>
          </div>
        </div>
      } @else {
        <div class="row">
          <div class="col text-center">
            <div class="input-group mb-3">
              <span class="input-group-text">
                <i class="fa-light fa-search"></i>
              </span>
              <input
                class="form-control"
                type="text"
                placeholder="{{ 'global.Rechercher' | translate }}"
                [(ngModel)]="search"
                (ngDelayedModelChange)="searchInTree(data)"
              />
              @if (search) {
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary h-100" type="button" (click)="search = ''">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>

        <ng-template let-treeItem let-level="level" #tree>
          <div class="ps-2 mt-2">
            <div class="pointer my-fs-4" [class.text-tertiary]="treeItem.matchSearch" (click)="toggle(treeItem)">
              <i class="fa-light" [class.fa-chevron-right]="!treeItem.open" [class.fa-chevron-down]="treeItem.open"></i>
              {{ treeItem.org.name }}
              <span class="my-fs-1 text-muted">
                (
                {{ treeItem.org.nbUsers }}
                <i class="fa-light fa-user"></i>
                )
              </span>
            </div>
            @if (treeItem.open) {
              <div class="ps-2">
                <div class="row">
                  @for (userItem of treeItem.users; track $index) {
                    @if ((search !== '' && userItem.matchSearch) || search === '') {
                      <div class="col-12 col-md-6 my-3">
                        <a
                          class="text-body"
                          routerLink="/user/{{ userItem.user.idUser }}/{{ string_to_slug(userItem.user.firstName + '-' + userItem.user.lastName) }}"
                        >
                          <hiji-user-card [user]="userItem.user" [showCallToAction]="false" [withHover]="true"></hiji-user-card>
                        </a>
                      </div>
                    }
                  }
                </div>
              </div>

              @for (child of treeItem.children; track $index) {
                @if (child.org.nbUsers > 0) {
                  <ng-container *ngTemplateOutlet="tree; context: { $implicit: child, level: level + 1 }"></ng-container>
                }
              }
            }
          </div>
        </ng-template>

        @for (treeItem of data; track $index) {
          <ng-container *ngTemplateOutlet="tree; context: { $implicit: treeItem, level: 0 }"></ng-container>
        }
      }
    </div>
  </div>
</div>

<a class="text-body" routerLink="/group/create">
  <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
    <div class="row g-1 py-3 flex-grow-1">
      <div class="col-auto d-flex align-items-center">
        <i class="fa-light fa-user-plus fa-2x text-tertiary align-icon"></i>
      </div>
      <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
        <div class="row">
          <div class="col-auto">{{ 'group.create-card.Créer un groupe de feedback' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</a>

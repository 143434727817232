import { Component, Input, OnInit } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { InquiryAnswerDTO } from 'common';

@Component({
    selector: 'hiji-inquiry360-answer-card',
    templateUrl: './inquiry360-answer-card.component.html',
    styleUrls: ['./inquiry360-answer-card.component.scss'],
    standalone: false
})
export class Inquiry360AnswerCardComponent implements OnInit {
  @Input()
  inquiryToAnswer: InquiryAnswerDTO;
  warningEndDate: boolean = false;
  string_to_slug = string_to_slug;

  constructor() {}

  ngOnInit(): void {
    this.warningEndDate = this.verifWarningEndDate();
  }

  verifWarningEndDate() {
    const newDate = new Date(new Date().setDate(new Date().getDate() + 3));
    return newDate >= this.inquiryToAnswer.endDate;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiji-group-create-card',
    templateUrl: './group-create-card.component.html',
    styleUrls: ['./group-create-card.component.scss'],
    standalone: false
})
export class GroupCreateCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

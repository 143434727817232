import { Component, Input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { FormationSessionActionDTO } from 'common';

@Component({
    selector: 'hiji-mif-participant-evaluation-actions-card',
    templateUrl: './mif-participant-evaluation-actions-card.component.html',
    styleUrls: ['./mif-participant-evaluation-actions-card.component.scss'],
    standalone: false
})
export class MifParticipantEvaluationActionsCardComponent {
  @Input()
  action: FormationSessionActionDTO;
  string_to_slug = string_to_slug;

  constructor() {}
}

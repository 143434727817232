import { Component, OnInit } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { CoachPerimeterOrganisationDTO, CoachService, UserInCoachPerimeterDTO } from 'common';

interface UserItem {
  user: UserInCoachPerimeterDTO;
  matchSearch: boolean;
}
interface TreeItem {
  org: CoachPerimeterOrganisationDTO;
  open: boolean;
  matchSearch: boolean;
  users: UserItem[];
  children: TreeItem[];
}
@Component({
  selector: 'hiji-coach-user-list',
  standalone: false,
  templateUrl: './coach-user-list.component.html',
  styleUrl: './coach-user-list.component.scss',
})
export class CoachUserListComponent implements OnInit {
  loading = true;
  data: TreeItem[];
  search: string = '';
  string_to_slug = string_to_slug;

  constructor(private coachService: CoachService) {}

  ngOnInit(): void {
    this.coachService
      .getUsersInMyPerimeters()
      .onErrorUseDefault()
      .execute((data) => {
        this.data = this.transform(data);
        this.loading = false;
      });
  }

  transform(data: CoachPerimeterOrganisationDTO[]): TreeItem[] {
    return data.map((org) => {
      return {
        org,
        open: false,
        matchSearch: false,
        users: org.users.map((u) => ({ user: u, matchSearch: false })),
        children: this.transform(org.suborgas),
      };
    });
  }

  searchInTree(data: TreeItem[]): boolean {
    let match = false;
    if (this.search === '') {
      data.forEach((org) => {
        org.matchSearch = false;
        org.users.forEach((u) => (u.matchSearch = false));
        org.open = false;
        this.searchInTree(org.children);
      });
    } else {
      data.forEach((org) => {
        org.users.forEach((u) => {
          u.matchSearch = (u.user.firstName + ' ' + u.user.lastName + ' ' + u.user.firstName).toLowerCase().includes(this.search.toLowerCase());
          if (u.matchSearch) org.matchSearch = true;
        });
        org.open = org.matchSearch;
        if (this.searchInTree(org.children)) org.open = true;
        match = match || org.matchSearch;
      });
    }
    return match;
  }

  toggle(treeItem: TreeItem) {
    treeItem.open = !treeItem.open;
  }
}

import { Component, Input } from '@angular/core';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { User } from '@sentry/angular';
import { AuthService, CertificationSessionLiteDTO, Config } from 'common';

@Component({
    selector: 'hiji-certification-session-card',
    templateUrl: './certification-session-card.component.html',
    styleUrl: './certification-session-card.component.scss',
    standalone: false
})
export class CertificationSessionCardComponent {
  @Input()
  certificationSessionLiteDTO: CertificationSessionLiteDTO;
  string_to_slug = string_to_slug;
  loggedUser: User;
  todayDate = new Date();

  constructor(
    private authService: AuthService,
    public envConfig: EnvService<Config>
  ) {
    if (authService.userBundle) this.loggedUser = authService.userBundle.user;
  }
}

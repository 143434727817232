import { Component, Input, OnInit } from '@angular/core';
import { FeedbackReaction, FeedbackUserReactionDTO } from 'common';

@Component({
    selector: 'hiji-fbs-reaction-card',
    templateUrl: './fbs-reaction-card.component.html',
    styleUrls: ['./fbs-reaction-card.component.scss'],
    standalone: false
})
export class FbsReactionCardComponent implements OnInit {
  @Input()
  reaction: FeedbackUserReactionDTO;
  FeedbackReaction: typeof FeedbackReaction = FeedbackReaction;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, Input } from '@angular/core';
import { AuthService, HomeDTO, TeamBoardLiteDTO } from 'common';

@Component({
    selector: 'hiji-teamboard-card',
    templateUrl: './teamboard-card.component.html',
    styleUrl: './teamboard-card.component.scss',
    standalone: false
})
export class TeamboardCardComponent {
  @Input()
  data: HomeDTO = null;
  @Input()
  teamboard: TeamBoardLiteDTO;
  constructor(public authService: AuthService) {}
}
